import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import UserListTable from "./app/component/userTable/Index";
import SpeedAnalysis from "./app/component/speedAnalysis/Index";
import View from "./app/component/view.js/Index";
import Point from "./app/component/point";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: <RedirectionWrapper to="/userlist/" />,
      },
      {
        path: "/userlist/",
        element: <UserListTable />,
      },
      {
        path: "/speedanalysis/",
        element: <SpeedAnalysis />,
      },
      {
        path: "/userlist/view/:userId/",
        element: <View />,
      },
      {
        path: "/userlist/point/:userId/",
        element: <Point />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <RedirectionWrapper to="/userlist/" />
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
