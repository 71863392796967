import { Button, Modal, Spinner } from "react-bootstrap";
import UserService from "../../services/user.service";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

export function DeleteUserModal(props) {
  const [deleteLoader, setDeleteLoader] = useState(false);

  const { deleteModal, deleteId, onHide, allData, emailUse } = props;
  const userDelete = async () => {
    setDeleteLoader(true);
    const response = await UserService.DeleteUser(deleteId);
    onHide();
    enqueueSnackbar("Deleted User", {
      variant: "success",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    setDeleteLoader(false);
    allData();
  };

  return (
    <>
      <Modal
        {...props}
        show={deleteModal}
        onHide={onHide}
        size="large"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton />
        <Modal.Body className="text-center">
          <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

          <h4 className="tx-danger tx-semibold mg-b-20">Delete?</h4>
          <p className="mg-b-20 mg-x-20 ">
            Are you sure want to delete {emailUse}?
          </p>

          <Button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              userDelete();
            }}
            variant="danger"
          >
            {deleteLoader ? <Spinner /> : "Delete"}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
