import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Searchable from "react-searchable-dropdown";
import Select from "react-select";

const AddUser = (props) => {
  const {
    show,
    handleClose,
    formik,
    id,
    isOpen,
    handleOpenMenu,
    handleCloseMenu,
    submitLoader,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const options = [
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" },
  ];
  const customHandleChange = (e) => {
    const data = e.target.value.toLowerCase();
    formik.setFieldValue("Email", data);
  };

  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h4 className="mt-3 font-weight-bold">
          {id ? "UPDATE USER" : "ADD USER"}
        </h4>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Email Address: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="Email"
                    placeholder="Email Address"
                    value={props.formik.values.Email}
                    onChange={(e) => customHandleChange(e)}
                    autoComplete="off"
                    autofill="false"
                  />
                  {props.formik.errors.Email && props.formik.touched.Email ? (
                    <p className="text-start error">
                      {props.formik.errors.Email}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Password: <span className="tx-danger">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      placeholder="Password"
                      autoComplete="off"
                      autofill="false"
                      value={formik.values.Password}
                      onChange={formik.handleChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        style={{
                          padding: "0.375rem 0.5rem",
                          border: "aliceblue",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <FaEyeSlash style={{ fontSize: "1rem" }} />
                        ) : (
                          <FaEye style={{ fontSize: "1rem" }} />
                        )}
                      </button>
                    </div>
                  </div>
                  {props.formik.errors.Password &&
                  props.formik.touched.Password ? (
                    <p className="text-start error">
                      {" "}
                      {props.formik.errors.Password}
                    </p>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationFormikRole"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Role: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Select
                    value={options.find(
                      (res) => res.value === props.formik.values.roles
                    )}
                    placeholder="Choose one"
                    options={options}
                    onMenuOpen={handleOpenMenu}
                    onMenuClose={handleCloseMenu}
                    menuIsOpen={isOpen}
                    onChange={(selected) => {
                      props.formik.setFieldValue("roles", selected.value);
                    }}
                  />
                  {props.formik.errors.roles && props.formik.touched.roles ? (
                    <p className="text-start error">
                      {props.formik.errors.roles}
                    </p>
                  ) : null}
                </Form.Group>
              </div>
              <Form.Group className="position-relative col-12"></Form.Group>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  {submitLoader ? <Spinner /> : id ? "Update" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddUser;
