import serverCall from "../../serverCall";

const recordUser = (startDate, endDate, page, limit) => {
  const response = serverCall.get(
    `/Find-All-Users-Records/admin?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`
  );
  return response;
};

const analysis = {
  recordUser,
};

export default analysis;
