import React from "react";
import { useState } from "react";
import Loader from "../../layout/loader/loader";
import { Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UserService from "../../services/user.service";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MapComponent from "../Map";
import { CircularProgress } from "@mui/material";
import withLoader from "../../layout/loader/withLoader";
import folder from "../../services/folder.service";
import moment from "moment";
import EditFolder from "./EditFolderModal";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import { DeleteFolder } from "./DeleteFolderModal";
import MapViewUser from "./MapViewUser";

const View = () => {
  const [data, setData] = useState([]);
  const [email, setEmail] = useState();
  const [totalRows, setTotalRows] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [titleId, setTitleId] = useState("");
  const location = useLocation();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [markersMap, setMarkersMap] = useState([]);
  const [showMap, setShowMap] = useState(false);

  const initialValues = {
    Title: "",
  };
  const validationSchema = yup.object().shape({
    Title: yup.string().required("Title is Required"),
  });
  const GetUser = async () => {
    try {
      setIsLoading(true);
      const response = await folder.findFolder(userId, pageNumber, dataPerPage);
      const result = response.data.data;
      setEmail(response.data.data[0].Email);
      setTotalRows(response.data.totalItems);
      setData(result);
    } catch (error) {
      console.error("Error fetching user record", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetUser();
  }, [userId, pageNumber, dataPerPage]);
  const handlePageChange = async (newPageNumber) => {
    setIsLoading(true);
    setPageNumber(newPageNumber);
    await GetUser();
    setIsLoading(false);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setIsLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await GetUser();
    setIsLoading(false);
  };
  const handleSubmitForm = async (values, action) => {
    try {
      const response = await folder.editFolder(titleId, values.Title);
      action.resetForm();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      handleClose();
      GetUser();
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });
  const handleOpen = async (userId) => {
    setTitleId(userId);
    setShow(true);
    const foundItem = data.find(
      (item) => (item?.titleId ? item?.titleId : item._id) === userId
    );
    if (foundItem) {
      formik.setFieldValue("Title", foundItem.title);
    }
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleDeleteNodal = () => setDeleteModal(false);
  const handleDelete = (userId) => {
    setDeleteModal(true);
    setDeleteId(userId);
  };
  const handleOpenMap = async () => {
    setShowMap(true);
    setMarkersMap(markersMap);
  };
  const handleCloseMap = () => {
    setShowMap(false);
  };
  const columns = [
    {
      name: <b>TITLE</b>,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: <b>VIEW POINT</b>,
      selector: (row) => row.viewPointCount,
      sortable: true,
    },
    {
      name: <b>USER HISTORY</b>,
      selector: (row) => row.usersHistoryCount,
      sortable: true,
    },
    {
      name: <b>CREATED DATE</b>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <div className="d-flex">
          <div className="me-2">
            <Link
              onClick={() => handleOpen(row?.titleId ? row?.titleId : row._id)}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-pencil"></i>
            </Link>
          </div>
          <div className="me-2">
            <Link
              onClick={() =>
                handleDelete(row?.titleId ? row?.titleId : row._id)
              }
              className="btn btn-danger btn-sm"
            >
              <i className="fe fe-trash"></i>
            </Link>
          </div>
          <div className="me-2">
            <div
              onClick={() => {
                navigate(
                  `/userlist/point/${row?.titleId ? row?.titleId : row._id}`,
                  {
                    state: {
                      id: userId,
                      email: location?.state?.email,
                    },
                  }
                );
              }}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-folder"></i>
            </div>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="page-header">
        <div className="d-flex align-items-center gap-2">
          <h2 className="main-content-title tx-24 p-0 m-0">Speed Analysis</h2>
          <p
            style={{
              fontWeight: "bold",
              marginTop: "revert",
              fontSize: "medium",
            }}
          >
            ({location?.state?.email})
          </p>
        </div>
        <Button
          variant="danger"
          type="button"
          className="btn btn-primary my-2 btn-icon-text"
          onClick={() => handleOpenMap("")}
        >
          <i className="fa fa-map-marker me-2"></i> Map
        </Button>
      </div>
      <Modal
        show={showMap}
        onHide={handleCloseMap}
        size="fullscreen"
        style={{ maxWidth: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MapViewUser userId={userId} />
        </Modal.Body>
      </Modal>
      {show && (
        <EditFolder
          show={show}
          handleClose={handleClose}
          formik={formik}
          titleId={titleId}
        />
      )}
      {
        <DeleteFolder
          deleteModal={deleteModal}
          onHide={handleDeleteNodal}
          allData={GetUser}
          deleteId={deleteId}
          setDeleteModal={setDeleteModal}
        />
      }
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <DataTable
                    data={data}
                    columns={columns}
                    noHeader
                    persistTableHead
                    pagination
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    highlightOnHover
                    striped
                    center={true}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    // onChangeRowsPerPage={(page, totalRows) => {
                    //     setDataPerPage(page);
                    //     setPageNumber(1);
                    // }}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withLoader(View);
