import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ThresholdService from "../../services/threshold.service";

const AddMarkerThreshold = (props) => {
  const { show, handleClose, id } = props;
  const [isEdit, setIsEdit] = useState(false);

  const editClick = () => {
    setIsEdit(!isEdit);
  };

  const formik = useFormik({
    initialValues: {
      downloadSpeed: "",
      uploadSpeed: "",
    },
    onSubmit: async (values, action) => {
      console.log(values);
      const { id, uploadSpeed, downloadSpeed } = values;
      const response = await ThresholdService.udateThreshold(id, {
        uploadSpeed,
        downloadSpeed,
      });
      console.log(response);
      action.resetForm();
      handleClose();
    },
  });

  const getThreshold = async () => {
    const response = await ThresholdService.getThreshold();
    console.log(response.data.data);
    const result = response.data.data;

    formik.setFieldValue("id", result._id);
    formik.setFieldValue("uploadSpeed", result.uploadSpeed);
    formik.setFieldValue("downloadSpeed", result.downloadSpeed);
  };
  useEffect(() => {
    getThreshold();
  }, []);

  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h4 className="mt-3 font-weight-bold">Marker</h4>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-12">
                {/* {!isEdit &&
                                    <div className='d-flex justify-content-end'>
                                        <Button className='btn btn-sm' onClick={editClick} type='button'><i className='fe fe-edit'></i> Edit</Button>
                                    </div>
                                } */}
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Upload Speed: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="uploadSpeed"
                    placeholder="Upload Speed"
                    value={formik.values.uploadSpeed}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    autofill="false"
                    disabled={isEdit ? false : true}
                  />
                  {formik.errors.uploadSpeed && formik.touched.uploadSpeed ? (
                    <p className="text-start error">
                      {formik.errors.uploadSpeed}
                    </p>
                  ) : null}
                </Form.Group>

                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Download Speed: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="downloadSpeed"
                    placeholder="Download Speed"
                    value={formik.values.downloadSpeed}
                    onChange={formik.handleChange}
                    autoComplete="off"
                    autofill="false"
                    disabled={isEdit ? false : true}
                  />
                  {formik.errors.downloadSpeed &&
                  formik.touched.downloadSpeed ? (
                    <p className="text-start error">
                      {formik.errors.downloadSpeed}
                    </p>
                  ) : null}
                </Form.Group>
              </div>
              <Form.Group className="position-relative col-12"></Form.Group>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {isEdit && (
                  <Button
                    type="submit"
                    style={{
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "15px",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {!isEdit && (
                <Button
                  type="button"
                  style={{ marginTop: "20px", marginRight: "15px" }}
                  onClick={editClick}
                >
                  <i className="fe fe-edit"></i> Edit
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddMarkerThreshold;
