import serverCall from "../../serverCall";

const findFolder = (UserId, page, limit) => {
  const response = serverCall.get(
    `/title/find-folder?UserId=${UserId}&page=${page}&limit=${limit}`
  );
  return response;
};
const deleteFolder = (titleId) => {
  const response = serverCall.delete(`/title/delete-folder?titleId=${titleId}`);
  return response;
};
const editFolder = (titleId, title) => {
  const response = serverCall.patch(
    `/title/update-folder?titleId=${titleId}&title=${title}`
  );
  return response;
};
const folder = {
  findFolder,
  deleteFolder,
  editFolder,
};

export default folder;
