import React, { useState } from "react";
import { Badge, Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import Select from "react-select";
import UserService from "../../services/user.service";
import Loader from "../../../assets/img/svgs/loader.svg";
import { useEffect } from "react";

const UserDetailsModal = (props) => {
  const {
    selectedOption,
    handleStatus,
    filteredOptions,
    newStatus,
    isShown,
    setBtnShow,
    filteredOptionsDecline,
    filteredOptionsVerify,
    Option,
    btnShow,
    oneUserDetails,
    handleScreenshotModal,
    newOptions,
    setNewOptions,
    onHide,
    users,
    failureMsg,
    setFailureMsg,
    isError,
    setIsError,
    screenshot,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const sponsorName = oneUserDetails?.sponserName == "";
  const sponserUrl = oneUserDetails?.sponserUrl == "";

  const handleFormSubmit = async () => {
    setSubmitLoader(true);
    console.log("failurMsg", failureMsg);

    if (!failureMsg && newStatus === "DECLINE") {
      setIsError(true);
      setSubmitLoader(false);
      return;
    }
    const msg = newStatus === "DECLINE" ? failureMsg : "";
    const response = await UserService.userStatus({
      userId: oneUserDetails?._id,
      verificationStatus: selectedOption,
      declineMessage: msg,
    });
    setTimeout(async () => {
      await users();
    }, 500);
    onHide();
    setSubmitLoader(false);
    setIsError(false);
    setBtnShow(false);
  };
  const handleChange = (e) => {
    const input = e.target.value;
    console.log("input", input);
    setBtnShow(true);
    if (input === "") {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setFailureMsg(input);
  };

  useEffect(() => {
    if (!oneUserDetails) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, []);

  return (
    <Modal
      {...props}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h4 className="mt-3 font-weight-bold">USER DETAILS</h4>
      </Modal.Header>
      <Modal.Body className="text-center">
        {isLoading ? (
          <img src={Loader} alt="Loader" />
        ) : (
          <div className="col-122">
            <div className="card custom-card">
              <div
                className="col-12"
                style={{
                  overflow: "auto",
                  paddingTop: "15px",
                  marginBottom: "15px",
                  maxHeight: "650px",
                  padding: "10px 10px 0px 10px",
                }}
              >
                <div className="mb-3 d-flex justify-content-end">
                  {/* {oneUserDetails?.verificationStatus === "PENDING" ? ( */}
                  <>
                    {" "}
                    {newOptions === "PENDING" ? (
                      <Select
                        defaultValue={selectedOption[0]}
                        isSearchable={false}
                        onChange={(selectedOption) =>
                          handleStatus(selectedOption)
                        }
                        options={filteredOptions}
                        components={{ Option }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: 130,
                            borderRadius: 5,
                            backgroundColor: "#F1911D",
                            padding: 0,
                          }),
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#F1911D",
                            borderRadius: 5,
                            color: "white",
                            height: "35px",
                            minHeight: "0px",
                            cursor: "pointer",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "white",
                            overflow: "visible",
                            marginLeft: "0px",
                            paddingBottom: "2px",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            padding: 0,
                            gap: 2,
                            cursor: "pointer",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            marginTop: "4px",
                          }),
                        }}
                        formatOptionLabel={(option) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <i
                              className={option.icon}
                              style={{ marginRight: "6px" }}
                            ></i>
                            {option.label}
                          </div>
                        )}
                      />
                    ) : newOptions === "DECLINE" ? (
                      <Select
                        defaultValue={selectedOption[2]}
                        isSearchable={false}
                        onChange={(selectedOption) =>
                          handleStatus(selectedOption)
                        }
                        options={filteredOptionsDecline}
                        components={{ Option }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: 130,
                            borderRadius: 5,
                            backgroundColor: "#FD6074",
                            padding: 0,
                          }),
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#FD6074",
                            borderRadius: 5,
                            color: "white",
                            height: "35px",
                            minHeight: "0px",
                            cursor: "pointer",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "white",
                            overflow: "visible",
                            marginLeft: "0px",
                            paddingBottom: "2px",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            padding: 0,
                            gap: 2,
                            cursor: "pointer",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            marginTop: "4px",
                          }),
                        }}
                        formatOptionLabel={(option) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <i
                              className={option.icon}
                              style={{ marginRight: "6px" }}
                            ></i>
                            {option.label}
                          </div>
                        )}
                      />
                    ) : (
                      <Select
                        defaultValue={selectedOption[1]}
                        isSearchable={false}
                        onChange={(selectedOption) =>
                          handleStatus(selectedOption)
                        }
                        options={filteredOptionsVerify}
                        components={{ Option }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: 130,
                            borderRadius: 5,
                            backgroundColor: "#15A552",
                            padding: 0,
                          }),
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: "#15A552",
                            borderRadius: 5,
                            color: "white",
                            height: "35px",
                            minHeight: "0px",
                            cursor: "pointer",
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: "white",
                            overflow: "visible",
                            marginLeft: "0px",
                            paddingBottom: "2px",
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            padding: 0,
                            gap: 2,
                            cursor: "pointer",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            marginTop: "4px",
                          }),
                        }}
                        formatOptionLabel={(option) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <i
                              className={option.icon}
                              style={{ marginRight: "6px" }}
                            ></i>
                            {option.label}
                          </div>
                        )}
                      />
                    )}
                  </>
                </div>
                <div>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Name"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control value={oneUserDetails?.name} disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Email"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control value={oneUserDetails?.Email} disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Role"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control value={oneUserDetails?.roles} disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"EWRC Url"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control value={oneUserDetails?.ewrcUrl} disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Sponsor Url"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      {/* <Form.Control value={oneUserDetails?.sponserUrl} disabled /> */}
                      <div
                        style={{
                          overflowY: "scroll",
                          padding: "10px",
                          height: sponserUrl ? "38px" : "72px",
                          borderRadius: "4px",
                          backgroundColor: "#f6f6ff",
                          border: "1px solid #e8e8f7",
                          textAlign: "start",
                        }}
                      >
                        {oneUserDetails?.sponserUrl?.map((item, index) => {
                          return (
                            <p className="m-0 mb-1" key={index}>
                              {item}
                            </p>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Verify"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <Form.Control value={oneUserDetails?.isVerify} disabled />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Sponsor Name"}
                      </Form.Label>
                    </Col>
                    <Col xs={8}>
                      <div
                        style={{
                          overflowY: "scroll",
                          padding: "10px",
                          height: sponsorName ? "38px" : "92px",
                          borderRadius: "4px",
                          backgroundColor: "#f6f6ff",
                          border: "1px solid #e8e8f7",
                          textAlign: "start",
                        }}
                      >
                        {oneUserDetails?.sponserName?.map((item, index) => {
                          return (
                            <Badge
                              key={index}
                              pill
                              variant="primary"
                              style={{
                                marginRight: "5px",
                                marginBottom: "3px",
                              }}
                            >
                              {item}
                            </Badge>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Status"}
                      </Form.Label>
                    </Col>
                    <Col xs={8} className="d-flex">
                      <button
                        type="button"
                        className={`btn btn-sm btn-${
                          oneUserDetails?.verificationStatus === "VERIFIED"
                            ? "success"
                            : oneUserDetails?.verificationStatus === "PENDING"
                            ? "warning"
                            : "danger"
                        } text-white`}
                        style={{
                          width: "110px",
                          height: "35px",
                          fontSize: "inherit",
                          textAlign: "center",
                          cursor: "default",
                        }}
                      >
                        <i
                          style={{ paddingRight: "3px" }}
                          class={`fa fa-${
                            oneUserDetails?.verificationStatus === "VERIFIED"
                              ? "check"
                              : oneUserDetails?.verificationStatus === "PENDING"
                              ? "clock"
                              : "close"
                          }`}
                        ></i>{" "}
                        {oneUserDetails?.verificationStatus === "DECLINE"
                          ? "Declined"
                          : oneUserDetails?.verificationStatus === "VERIFIED"
                          ? "Verified"
                          : "Pending"}
                      </button>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={4} className="d-flex align-items-center">
                      <Form.Label
                        className="fw-bold text-start"
                        style={{ color: "black", marginBottom: "0px" }}
                      >
                        {"Racing License"}
                      </Form.Label>
                    </Col>
                    <Col xs={8} className="d-flex">
                      {oneUserDetails?.verifiedPhoto?.length > 0 ? (
                        <>
                          <div
                            className="image"
                            style={{
                              height: "167px",
                              width: "136px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleScreenshotModal(oneUserDetails)
                            }
                          >
                            <img
                              src={oneUserDetails?.verifiedPhoto[0]}
                              style={{ borderRadius: "11px" }}
                              alt="image"
                              className="img-fit-cover"
                            />
                          </div>
                          {/* {isOpenDocs && selectedKycDocs === item && <FileTypeModal show={isOpenDocs} onHide={() => setIsOpenDocs(false)} contentUrl={modalContentUrl} />} */}
                        </>
                      ) : (
                        <p className="m-0">Image is not available</p>
                      )}
                    </Col>
                  </Row>

                  {isShown && (
                    <Row className="mb-2">
                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Label
                          className="fw-bold text-start"
                          style={{ color: "black", marginBottom: "0px" }}
                        >
                          {"Decline Message"}
                        </Form.Label>
                      </Col>
                      <Col xs={8}>
                        <Form.Control
                          type="text"
                          placeholder="Enter reason"
                          value={failureMsg}
                          onChange={(e) => handleChange(e)}
                          as="textarea"
                          rows={3}
                        />
                        {isError && (
                          <p className="text-start error">
                            {"Enter the decline message!"}
                          </p>
                        )}
                      </Col>
                    </Row>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {btnShow && (
                    <Button
                      type="button"
                      onClick={handleFormSubmit}
                      style={{
                        display: "flex",
                        marginLeft: "auto",
                        marginRight: "15px",
                        marginTop: "20px",
                        textAlign: "center",
                      }}
                    >
                      {submitLoader ? <Spinner /> : "Submit"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserDetailsModal;
