import serverCall from "../../serverCall";

const viewPoint = () => {
  const response = serverCall.get(`/find-view-point`);
  return response;
};
const viewPointTab = (titleId, page, limit) => {
  const response = serverCall.get(
    `/find-title-view-point?titleId=${titleId}&page=${page}&limit=${limit}`
  );
  return response;
};
const viewPointTabMap = (titleId) => {
  const response = serverCall.get(`/find-title-view-point?titleId=${titleId}`);
  return response;
};
const dataPointTab = (titleId, page, limit) => {
  const response = serverCall.get(
    `/find-title-history?titleId=${titleId}&page=${page}&limit=${limit}`
  );
  return response;
};
const dataPointTabMap = (titleId) => {
  const response = serverCall.get(`/find-title-history?titleId=${titleId}`);
  return response;
};
const getAllPoints = (userId) => {
  const response = serverCall.get(`admin/get-map-details?userId=${userId}`);
  return response;
};
const view = {
  viewPoint,
  viewPointTab,
  dataPointTab,
  dataPointTabMap,
  viewPointTabMap,
  getAllPoints,
};

export default view;
