import { get } from "lodash";
import serverCall from "../../serverCall";

const signUp = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;

  const response = await serverCall.post(
    `/Auth/Login-Admin?Email=${email}&Password=${password}`
  );
  const data = get(response, "data", null);

  if (data) {
    const user = data.user;
    const token = data.accessToken;
    const userAndToken = {
      user,
      token,
    };
    localStorage.setItem("rallyBeacon", JSON.stringify(userAndToken));
  }
  return response;
};

const AuthenticationService = {
  signUp,
};

export default AuthenticationService;
