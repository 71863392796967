import serverCall from "../../serverCall";

const CreateUser = (data) => {
  const response = serverCall.post("/Create-User/admin", data);
  return response;
};

const UpdateUser = (UserId, data) => {
  const response = serverCall.post(`/Update-User/admin?UserId=${UserId}`, data);
  return response;
};
const getUser = (userId, page, limit) => {
  const response = serverCall.get(
    `/Find-One-User-Detail/admin/{UserId}?userId=${userId}&page=${page}&limit=${limit}`
  );
  return response;
};
const getUserDetails = (userId) => {
  const response = serverCall.get(
    `/Get-User-Detail/admin/{UserId}?UserId=${userId}`
  );
  return response;
};
const getUserAllData = (userId) => {
  const response = serverCall.get(
    `/Find-One-User-Detail/admin/{UserId}?userId=${userId}`
  );
  return response;
};

const getUserUpdate = (userId) => {
  const response = serverCall.get(
    `/Find-One-User-Detail/admin/{UserId}?userId=${userId}`
  );
  return response;
};
const getUserRecord = (UserId) => {
  const response = serverCall.get(
    `/find-one-user-records/admin?UserId=${UserId}`
  );
  return response;
};
const DeleteUser = (UserId) => {
  const response = serverCall.delete(
    `/Delete-One-User/admin/{UserId}?UserId=${UserId}`
  );
  return response;
};

const AsignRole = () => {
  const response = serverCall.post(
    `/Assign-Role-User/admin?UserId=6502b67a9316d47d9828e627&Assign-Role=user`
  );
  return response;
};
const userStatus = (data) => {
  const response = serverCall.post("Update-User-Status/admin", data);
  return response;
};
const AllUserDetaile = async (search, page, limit) => {
  const response = await serverCall.get(
    `/Find-All-User-Detail/admin?search=${search}&page=${page}&limit=${limit}`
  );
  return response;
};

const UserService = {
  CreateUser,
  AllUserDetaile,
  UpdateUser,
  getUser,
  getUserDetails,
  userStatus,
  getUserUpdate,
  getUserRecord,
  DeleteUser,
  AsignRole,
  getUserAllData,
};

export default UserService;
