import React from "react";
import { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UserService from "../services/user.service";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import withLoader from "../layout/loader/withLoader";
import ViewService from "../services/view.service";
import folder from "../services/folder.service";
import moment from "moment";
import { Tab } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import MapComponent from "./Map";

const Point = () => {
  const [data, setData] = useState([]);
  const [dataPoint, setDataPoint] = useState([]);
  const [email, setEmail] = useState();
  const [show, setShow] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [totalRowsData, setTotalRowsData] = useState();
  const [totalRowsView, setTotalRowsView] = useState();
  const [dataPerPageView, setDataPerPageView] = useState(10);
  const [dataPerPageData, setDataPerPageData] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);

  const { userId } = useParams();
  const location = useLocation();
  const UserId = location.state.id;
  const UserEmail = location.state.email;
  const handleOpen = async () => {
    setShow(true);
    setMarkers(markers);
  };
  const handleClose = () => {
    setShow(false);
  };
  const ViewDataPoint = async () => {
    try {
      setIsLoadingView(true);
      const response = await ViewService.viewPointTab(
        userId,
        pageNumber,
        dataPerPageView
      );
      const result = response.data.data;
      setEmail(response?.data?.data[0]?.Email);
      setTotalRowsView(response?.data?.TotalCount);
      setData(result);
    } catch (error) {
      console.error("Error fetching user record", error);
    } finally {
      setIsLoadingView(false);
    }
  };
  const DataPoint = async () => {
    try {
      setIsLoadingData(true);
      const response = await ViewService.dataPointTab(
        userId,
        pageNumber,
        dataPerPageData
      );
      const result = response.data.data;
      setTotalRowsData(response.data.TotalCount);
      setDataPoint(result);
    } catch (error) {
      console.error("Error fetching user record", error);
    } finally {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    ViewDataPoint();
    DataPoint();
  }, [userId, pageNumber, dataPerPageView, dataPerPageData]);
  const handlePageChangeData = async (newPageNumber) => {
    setIsLoadingData(true);
    setPageNumber(newPageNumber);
    await DataPoint();
    setIsLoadingData(false);
  };
  const handlePageChangeView = async (newPageNumber) => {
    setIsLoadingView(true);
    setPageNumber(newPageNumber);
    await DataPoint();
    setIsLoadingView(false);
  };
  const handlePerPageChangeData = async (newPerPage, newPageNumber) => {
    setIsLoadingData(true);
    setDataPerPageData(newPerPage);
    setPageNumber(newPageNumber);
    await DataPoint();
    setIsLoadingData(false);
  };
  const handlePerPageChangeView = async (newPerPage, newPageNumber) => {
    setIsLoadingView(true);
    setDataPerPageView(newPerPage);
    setPageNumber(newPageNumber);
    await DataPoint();
    setIsLoadingView(false);
  };
  const columns = [
    {
      name: <b>LOCATION</b>,
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: <b>ORDER</b>,
      selector: (row) => row.order,
      sortable: true,
    },
    {
      name: <b>LATITUDE</b>,
      selector: (row) => row.Latitude,
      sortable: true,
    },
    {
      name: <b>LONGITUDE</b>,
      selector: (row) => row.Longitude,
      sortable: true,
    },
    {
      name: <b>CREATED DATE</b>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
  ];

  const columnsData = [
    {
      name: <b>DATE</b>,
      selector: (row) => row.Date,
      // selector: (row) => moment(row.Date).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>TIME</b>,
      selector: (row) => row.Time,
      sortable: true,
    },
    {
      name: <b>UPLOAD SPEED</b>,
      selector: (row) => row.uploadSpeed,
      sortable: true,
    },
    {
      name: <b>DOWNLOAD SPEED</b>,
      selector: (row) => row.downloadSpeed,
      sortable: true,
    },
    {
      name: <b>LATITUDE</b>,
      selector: (row) => row.Latitude,
      sortable: true,
    },
    {
      name: <b>LONGITUDE</b>,
      selector: (row) => row.Longitude,
      sortable: true,
    },
  ];

  return (
    <>
      <div className="page-header">
        <div className="d-flex align-items-center gap-2">
          <h2 className="main-content-title tx-24 p-0 m-0">Speed Analysis</h2>
          <p
            style={{
              fontWeight: "bold",
              marginTop: "revert",
              fontSize: "medium",
            }}
          >
            ({UserEmail})
          </p>
        </div>
        <Button
          variant="danger"
          type="button"
          className="btn btn-primary my-2 btn-icon-text"
          // className="btn btn-primary btn-sm"
          onClick={() => handleOpen("")}
        >
          <i className="fa fa-map-marker me-2"></i> Map
        </Button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="fullscreen"
        style={{ maxWidth: "100%" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MapComponent UserId={UserId} />
        </Modal.Body>
      </Modal>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <Tabs
                    defaultActiveKey="viewpoint"
                    className="nav panel-tabs  main-nav-line justify-content-center"
                  >
                    <Tab
                      className="border border-top-0"
                      eventKey="viewpoint"
                      title="View Point"
                    >
                      <DataTable
                        data={data}
                        columns={columns}
                        noHeader
                        persistTableHead
                        highlightOnHover
                        striped
                        center={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRowsView}
                        paginationPerPage={dataPerPageView}
                        onChangeRowsPerPage={(page, totalRows) => {
                          handlePerPageChangeView(page, 1);
                        }}
                        onChangePage={handlePageChangeView}
                        progressPending={isLoadingView}
                        progressComponent={
                          <CircularProgress
                            size={30}
                            style={{ marginTop: "50px" }}
                          />
                        }
                      />
                    </Tab>
                    <Tab
                      className="border border-top-0"
                      eventKey="datapoint"
                      title="Data Point"
                    >
                      <DataTable
                        data={dataPoint}
                        columns={columnsData}
                        noHeader
                        persistTableHead
                        highlightOnHover
                        striped
                        center={true}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRowsData}
                        paginationPerPage={dataPerPageData}
                        onChangeRowsPerPage={(page, totalRows) => {
                          handlePerPageChangeData(page, 1);
                        }}
                        onChangePage={handlePageChangeData}
                        progressPending={isLoadingData}
                        progressComponent={
                          <CircularProgress
                            size={30}
                            style={{ marginTop: "50px" }}
                          />
                        }
                      />
                    </Tab>
                  </Tabs>
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withLoader(Point);
