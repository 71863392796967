import React from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { Card } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import analysis from "../../services/analysis.service";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Loader from "../../layout/loader/loader";
import withLoader from "../../layout/loader/withLoader";
import { CircularProgress } from "@mui/material";

const SpeedAnalysis = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("MM/DD/YYYY")
  );

  const setDates = (e, { startDate, endDate }) => {
    setStartDate(startDate.format("MM/DD/YYYY"));
    setEndDate(endDate.format("MM/DD/YYYY"));
  };

  const userRecords = async () => {
    try {
      setIsLoading(true);
      const response = await analysis.recordUser(
        startDate,
        endDate,
        pageNumber,
        dataPerPage
      );
      setData(response.data.data);
      setTotalRows(response.data.totalFilterCount);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    userRecords();
  }, [startDate, endDate, pageNumber, dataPerPage]);

  const handlePageChange = async (newPageNumber) => {
    setPageNumber(newPageNumber);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
  };

  const columns = [
    {
      name: <b>USER</b>,
      selector: (row) => row.Email,
      sortable: true,
    },
    {
      name: <b>DOWNLOAD SPEED</b>,
      selector: (row) => row.uploadSpeed,
      sortable: true,
    },
    {
      name: <b>UPLOAD SPEED</b>,
      selector: (row) => row.downloadSpeed,
      sortable: true,
    },
    {
      name: <b>LATITUDE</b>,
      selector: (row) => {
        return row.Latitude.toFixed(4);
      },
      sortable: true,
    },
    {
      name: <b>LONGTITUDE</b>,
      selector: (row) => {
        return row.Longitude.toFixed(4);
      },
      sortable: true,
    },
    {
      name: <b>DATE</b>,
      selector: (row) => row.Date,
      // selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>TIME</b>,
      selector: (row) => row.Time,
      // selector: (row) => moment(parseInt(row.Time)).format("HH:mm:ss"),
      sortable: true,
    },
  ];

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Speed Analysis</h2>
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "14px",
                    }}
                  >
                    <DateRangePicker
                      initialSettings={{
                        startDate: moment(new Date())
                          .subtract(1, "day")
                          .format("MM/DD/YYYY"),
                        endDate: moment(new Date()).format("MM/DD/YYYY"),
                      }}
                      onApply={setDates}
                    >
                      <input
                        type="text"
                        id="dateRangePicker"
                        style={{ width: "210px" }}
                        value={startDate + "  -  " + endDate}
                        className="form-control"
                      />
                    </DateRangePicker>
                  </div>
                  {/* {isLoading ? (<Loader />) : ( */}
                  <DataTable
                    data={data}
                    columns={columns}
                    noHeader
                    persistTableHead
                    pagination
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    highlightOnHover
                    striped
                    center={true}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    // onChangeRowsPerPage={(page, totalRows) => {
                    //   setDataPerPage(page);
                    //   setPageNumber(1);
                    // }}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                  {/* )} */}
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withLoader(SpeedAnalysis);
