import React, { useRef } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { Button, Card, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { useState } from "react";
import UserService from "../../services/user.service";
import moment from "moment";
import AddUser from "./AddUserModal";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link, useLocation } from "react-router-dom";
import { DeleteUserModal } from "./DeleteUserModal";
import { enqueueSnackbar } from "notistack";
import withLoader from "../../layout/loader/withLoader";
import { CircularProgress } from "@mui/material";
import MapComponent from "../Map";
import MapUsers from "../MapUsers";
import AddMarkerThreshold from "./AddMarkerThreshold";
import UserDetailsModal from "./UserDetailsModal";
import LicenseModal from "./LicenseModal";

const options = [
  { value: "PENDING", label: "Pending", color: "#F1911D", icon: "fa fa-clock" },
  {
    value: "VERIFIED",
    label: "Verifiy",
    color: "#15A552",
    icon: "fa fa-check",
  },
  { value: "DECLINE", label: "Decline", color: "#fd6074", icon: "fa fa-close" },
];
const filteredOptions = options?.filter((option) => option.value !== "PENDING");
const filteredOptionsDecline = options?.filter(
  (option) => option.value !== "DECLINE"
);
const filteredOptionsVerify = options?.filter(
  (option) => option.value !== "VERIFIED"
);
const Option = ({ innerProps, label, data }) => (
  <div
    {...innerProps}
    className="text-white d-flex align-items-center justify-content-center m-1"
    style={{ backgroundColor: data.color, borderRadius: "3px", height: 28 }}
  >
    <i className={data.icon} style={{ marginRight: "5px" }}>
      {" "}
    </i>{" "}
    {label}
  </div>
);
const UserListTable = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [btnShow, setBtnShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [id, setId] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [emailUse, setEmailUse] = useState("");
  const [searchRecord, setSearchRecord] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [totalRows, setTotalRows] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [openMarker, setOpenMarker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options);
  const [oneUserDetails, setOneUserDetails] = useState("");
  const [screenshot, setScreenshots] = useState([]);
  const [showLicense, setShowLicense] = useState(false);
  const [newOptions, setNewOptions] = useState("");
  const [failureMsg, setFailureMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const handleOpenMenu = () => {
    setIsOpen(true);
  };
  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handlePageChange = async (newPageNumber) => {
    setPageNumber(newPageNumber);
  };

  const initialValues = {
    Email: "",
    Password: "",
    roles: "",
  };

  const validationSchema = yup.object().shape({
    Email: yup.string().required("Email is Required"),
    Password: yup.string().min(8).required("Password is required!"),
    roles: yup.string().required("Roles is required"),
  });
  const handleClose = () => {
    setShow(false);
  };

  const handleOpenMap = async () => {
    setShowMap(true);
  };
  const handleCloseMap = () => {
    setShowMap(false);
  };

  const optionsData = [
    {
      value: "user",
      label: "user",
    },
    {
      value: "admin",
      label: "admin",
    },
  ];
  const handleSubmitForm = async (values, action) => {
    if (!values._id) {
      try {
        setSubmitLoader(true);
        const response = await UserService.CreateUser(values);
        action.resetForm();

        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        users();
        setSubmitLoader(false);
        handleClose();
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        setSubmitLoader(true);
        const response = await UserService.UpdateUser(values._id, values);
        action.resetForm();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        handleClose();
        users();
        setSubmitLoader(false);
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const handleOpen = async (id) => {
    setShow(true);
    if (id) {
      setId(true);
      const response = await UserService.getUserUpdate(id);
      const result = response.data;
      formik.setFieldValue("Email", result.data.userData.Email);
      formik.setFieldValue("Password", result.data.userData.Password0);
      formik.setFieldValue("roles", result.data.userData.roles);
      formik.setFieldValue("_id", result.data.userData._id);
    } else {
      setId(false);
      formik.setFieldValue("Email", "");
      formik.setFieldValue("Password", "");
      formik.setFieldValue("roles", "");
    }
  };

  const openMarkerModal = () => {
    setOpenMarker(true);
  };
  const users = async () => {
    try {
      setIsLoading(true);
      const response = await UserService.AllUserDetaile(
        searchValue,
        pageNumber,
        dataPerPage
      );
      setData(response.data.data);
      setTotalRows(response.data.totalCount);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    users();
  }, [searchValue, pageNumber, dataPerPage]);

  const handleDeleteNodal = () => setDeleteModal(false);
  const handleDelete = (id, Email) => {
    setDeleteModal(true);
    setDeleteId(id);
    setEmailUse(Email);
  };

  const handleStatus = async (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setNewStatus(selectedOption.value);
    setNewOptions(selectedOption.value);
    setBtnShow(true);
    if (selectedOption.value === "DECLINE") {
      setIsShown(true);
    } else {
      setFailureMsg("");
      setIsShown(false);
    }
  };
  const handleCloseUser = async () => {
    setSelectedOption(options);
    setUserModal(false);
    setIsShown(false);
    setIsError(false);
    setOneUserDetails("");
  };
  const handleLicenseClose = () => {
    setShowLicense(false);
    setUserModal(true);
  };
  const handleUserDetails = async (id) => {
    setUserModal(true);
    const response = await UserService.getUserDetails(id);
    const result = response?.data?.data;
    setOneUserDetails(result);
    setNewStatus(result.verificationStatus);
    if (result.verificationStatus === "DECLINE") {
      setIsShown(true);
    }
    setNewOptions(result.verificationStatus);
    setFailureMsg(result.declineMessage);
  };
  const handleScreenshotModal = async (row) => {
    setShowLicense(true);
    setUserModal(false);
    const images = row?.verifiedPhoto || [];
    setScreenshots(images);

    // const fileUrl = row?.verifiedPhoto;
    // setScreenshots(fileUrl);
  };
  const DEBOUNCE_THRESHOLD = 500;
  const timeoutHandler = useRef(null);
  const debounceSearch = (event) => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }
    // setIsLoading(true)
    timeoutHandler.current = setTimeout(() => {
      setsearchValue(event);
      // setIsLoading(false)
    }, DEBOUNCE_THRESHOLD);
  };
  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
  };
  const columns = [
    {
      name: <b>USER</b>,
      selector: (row) => <div title={row.Email}>{row.Email}</div>,
      sortable: true,
    },
    {
      name: <b>PASSWORD</b>,
      selector: (row) => row.Password0,
      sortable: true,
    },
    {
      name: <b>ROLE</b>,
      selector: (row) => row.roles,
      sortable: true,
    },
    {
      name: <b>CREATED DATE</b>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>UPDATED DATE</b>,
      selector: (row) => moment(row.updatedAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: <b>STATUS</b>,
      selector: (row) => (
        <button
          className={`btn btn-sm action-btn btn-${
            row.verificationStatus === "VERIFIED"
              ? "success"
              : row.verificationStatus === "PENDING"
              ? "warning"
              : "danger"
          } text-white`}
          style={{
            width: "110px",
            textAlign: "center",
            cursor: "default",
          }}
        >
          <i
            style={{ paddingRight: "3px" }}
            class={`fa fa-${
              row.verificationStatus === "VERIFIED"
                ? "check"
                : row.verificationStatus === "PENDING"
                ? "clock"
                : "close"
            }`}
          ></i>{" "}
          {row.verificationStatus === "VERIFIED"
            ? "Verified"
            : row.verificationStatus === "PENDING"
            ? "Pending"
            : "Decline"}
        </button>
      ),
      sortable: true,
      width: "160px",
    },
    {
      name: <b>ACTION</b>,
      selector: (row) => (
        <div className="d-flex">
          <div className="me-2">
            <Link
              onClick={() => handleOpen(row._id)}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-pencil"></i>
            </Link>
          </div>
          <div className="me-2">
            <Link
              onClick={() => handleDelete(row._id, row.Email)}
              className="btn btn-danger btn-sm"
            >
              <i className="fe fe-trash"></i>
            </Link>
          </div>
          <div className="me-2">
            <Link
              to={`/userlist/view/${row._id}`}
              state={{
                email: row.Email,
              }}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </div>
          <div>
            <Link
              onClick={() => handleUserDetails(row._id)}
              className="btn btn-primary btn-sm"
            >
              <i className="fa fa-user"></i>
            </Link>
          </div>
        </div>
      ),
      width: "200px",
    },
  ];

  const closeDialog = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Users</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="mx-2 btn-icon-text"
              onClick={openMarkerModal}
            >
              <i className="ti-marker-alt me-2"></i> Marker Threshold
            </Button>
            <Button
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => handleOpen("")}
            >
              <i className="fe fe-user-plus me-2"></i> Add User
            </Button>
            {openMarker && (
              <AddMarkerThreshold
                show={openMarker}
                handleClose={() => setOpenMarker(false)}
              />
            )}
            {show && (
              <AddUser
                show={show}
                handleClose={closeDialog}
                formik={formik}
                submitLoader={submitLoader}
                id={id}
                optionsData={optionsData}
                isOpen={isOpen}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
              />
            )}
            {
              <DeleteUserModal
                deleteModal={deleteModal}
                onHide={handleDeleteNodal}
                allData={users}
                deleteId={deleteId}
                setDeleteModal={setDeleteModal}
                emailUse={emailUse}
              />
            }
            {userModal && (
              <UserDetailsModal
                show={userModal}
                onHide={handleCloseUser}
                setBtnShow={setBtnShow}
                newStatus={newStatus}
                selectedOption={selectedOption}
                handleStatus={handleStatus}
                filteredOptions={filteredOptions}
                filteredOptionsDecline={filteredOptionsDecline}
                filteredOptionsVerify={filteredOptionsVerify}
                Option={Option}
                btnShow={btnShow}
                oneUserDetails={oneUserDetails}
                handleScreenshotModal={handleScreenshotModal}
                setNewOptions={setNewOptions}
                newOptions={newOptions}
                users={users}
                setFailureMsg={setFailureMsg}
                failureMsg={failureMsg}
                isError={isError}
                setIsError={setIsError}
                isShown={isShown}
                screenshot={screenshot}
              />
            )}
            {showLicense && (
              <LicenseModal
                show={showLicense}
                onHide={handleLicenseClose}
                screenshot={screenshot}
              />
            )}
          </div>
        </div>
      </div>
      <div lg={12} className="w-100">
        <Card className="custom-card overflow-hidden">
          <Card.Body>
            <div className="responsive">
              <Card className="custom-card overflow-hidden">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "14px",
                      marginTop: "4px",
                    }}
                  >
                    <Button
                      variant="danger"
                      type="button"
                      // className="btn btn-primary my-2 btn-icon-text"
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenMap("")}
                    >
                      <i className="fa fa-map-marker me-2"></i> Map
                    </Button>
                    <Modal
                      show={showMap}
                      onHide={handleCloseMap}
                      size="fullscreen"
                      style={{ maxWidth: "100%" }}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Map</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <MapUsers />
                      </Modal.Body>
                    </Modal>
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchRecord}
                      onChange={(e) => {
                        setSearchRecord(e.target.value);
                        debounceSearch(e.target.value);
                      }}
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "4px",
                        padding: "0.375rem 0.75rem",
                      }}
                    />
                  </div>
                  {/* <div className="table-scroll" style={{ maxHeight: "400px", overflowY: "auto" }}> */}
                  <DataTable
                    data={data}
                    columns={columns}
                    noHeader
                    persistTableHead
                    center={true}
                    highlightOnHover
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={dataPerPage}
                    onChangeRowsPerPage={(page, totalRows) => {
                      handlePerPageChange(page, 1);
                    }}
                    onChangePage={handlePageChange}
                    progressPending={isLoading}
                    progressComponent={
                      <CircularProgress
                        size={30}
                        style={{ marginTop: "50px" }}
                      />
                    }
                  />
                  {/* </div> */}
                </div>
              </Card>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default withLoader(UserListTable);
