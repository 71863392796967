import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Searchable from "react-searchable-dropdown";

const EditFolder = (props) => {
  const { show, handleClose, formik } = props;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      size="large"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <h4 className="mt-3 font-weight-bold">EDIT FOLDER</h4>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="col-122">
          <div className="card custom-card">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <Form.Group
                  controlId="validationFormik101"
                  className="position-relative"
                >
                  <Form.Label
                    style={{
                      textAlign: "start",
                      color: "#000",
                      marginTop: "15px",
                    }}
                  >
                    Title: <span className="tx-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Title"
                    placeholder="Title"
                    value={props.formik.values.Title}
                    onChange={props.formik.handleChange}
                  />
                  {props.formik.errors.Title && props.formik.touched.Title ? (
                    <p className="text-start error">
                      {props.formik.errors.Title}
                    </p>
                  ) : null}
                </Form.Group>
              </div>
              <Form.Group className="position-relative col-12"></Form.Group>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "15px",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                >
                  UPDATE
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditFolder;
