import serverCall from "../../serverCall";

const getThreshold = () => {
  const response = serverCall.get(`/threashold`);
  return response;
};

const udateThreshold = (id, body) => {
  const response = serverCall.patch(`/threashold/${id}`, body);
  return response;
};
const ThresholdService = {
  getThreshold,
  udateThreshold,
};

export default ThresholdService;
