import React, { useEffect, useState } from "react";
import GreenMarker from "../../assets/img/users/marker_green.png";
import RedMarker from "../../assets/img/users/marker_red.png";
import YellowMarker from "../../assets/img/users/marker_yellow.png";
import BlueMarker from "../../assets/img/users/marker_blue.png";
import ViewService from "../services/view.service";
import { useParams } from "react-router-dom";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import ThresholdService from "../services/threshold.service";

function MapComponent() {
  const [mapData, setMapData] = useState([]);
  const [mapDataY, setMapDataY] = useState([]);
  const [limitDownload, setLimitDownload] = useState("");
  const [limitUpload, setLimitUpload] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedMarkerY, setSelectedMarkerY] = useState(null);
  const [selectedMarkerPosition, setSelectedMarkerPosition] = useState(null);
  const [selectedMarkerPositionY, setSelectedMarkerPositionY] = useState(null);
  const { userId } = useParams();
  const iconUrls = {
    green: GreenMarker,
    blue: BlueMarker,
    red: RedMarker,
    yellow: YellowMarker,
  };
  const GetAllUserData = async () => {
    try {
      const response = await ViewService.dataPointTabMap(userId);
      setMapData(response.data.data);
      console.log("other", response?.data?.data);
      response?.data?.data.map((item, index) =>
        setSelectedMarkerPosition({ lat: item.Latitude, lng: item.Longitude })
      );
    } catch (error) {
      console.error("Error fetching user record", error);
    } finally {
    }
  };
  const ViewDataPointMap = async () => {
    try {
      const response = await ViewService.viewPointTabMap(userId);
      const result = response?.data?.data;
      setMapDataY(result);
      response?.data?.data.map((item, index) =>
        setSelectedMarkerPositionY({ lat: item.Latitude, lng: item.Longitude })
      );
    } catch (error) {
      console.error("Error fetching user record", error);
    } finally {
    }
  };

  const getThreshold = async () => {
    const response = await ThresholdService.getThreshold();
    console.log(response.data.data);
    const result = response.data.data;
    setLimitDownload(response.data.data.downloadSpeed);
    setLimitUpload(response.data.data.uploadSpeed);
  };

  useEffect(() => {
    GetAllUserData();
    ViewDataPointMap();
    getThreshold();
  }, []);

  const customMarkerObjects = (marker) => {
    let customMarker;

    const uploadSpeedValue = parseFloat(
      marker.uploadSpeed && marker.uploadSpeed.match(/[\d.]+/)
    );
    const downloadSpeedValue = parseFloat(
      marker.downloadSpeed && marker.downloadSpeed.match(/[\d.]+/)
    );

    if (uploadSpeedValue !== NaN && downloadSpeedValue !== NaN) {
      if (
        downloadSpeedValue > limitDownload &&
        uploadSpeedValue > limitUpload
      ) {
        customMarker = iconUrls.green;
      } else if (
        downloadSpeedValue > limitDownload ||
        uploadSpeedValue > limitUpload
      ) {
        customMarker = iconUrls.blue;
      } else if (
        downloadSpeedValue <= limitDownload &&
        uploadSpeedValue <= limitUpload
      ) {
        customMarker = iconUrls.red;
      }
    }
    return customMarker;
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const center = {
    lat: -3.745,
    lng: -38.523,
  };
  const [map, setMap] = React.useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA2i9PW1yqgptKJNTH0oL7vERdmMAE9upk",
    libraries: ["places"],
    id: "google-map-script",
    version: "weekly",
    language: "en",
    region: "US",
    loading: "async",
  });
  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();

      mapData.forEach((marker) => {
        bounds.extend(
          new window.google.maps.LatLng(marker.Latitude, marker.Longitude)
        );
      });

      map.fitBounds(bounds);
      setMap(map);
    },
    [mapData]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    // <LoadScript googleMapsApiKey={"AIzaSyA2i9PW1yqgptKJNTH0oL7vERdmMAE9upk"}>
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={
        selectedMarkerPosition || selectedMarkerPositionY
          ? selectedMarkerPosition || selectedMarkerPositionY
          : center
      }
      zoom={selectedMarkerPosition ? 10 : 3}
      options={{
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {mapData?.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.Latitude, lng: marker.Longitude }}
          icon={{
            url: customMarkerObjects(marker),
            scaledSize: new window.google.maps.Size(32, 32),
            anchor: new window.google.maps.Point(16, 32),
          }}
          onClick={() => {
            setSelectedMarker(marker);
            setSelectedMarkerPosition({
              lat: marker.Latitude,
              lng: marker.Longitude,
            });
          }}
        >
          {selectedMarker === marker && (
            <InfoWindow
              position={{ lat: marker.Latitude, lng: marker.Longitude }}
              onCloseClick={() => {
                setSelectedMarker(null);
              }}
            >
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  {marker.Email}
                </div>
                <div className="d-flex justify-content-between mb-1">
                  <span>Lat: {marker.Latitude.toFixed(3)}</span>
                  <span>Long: {marker.Longitude.toFixed(3)}</span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  Upload speed: <span>{marker.uploadSpeed}</span>
                </div>
                <div className="mb-1 d-flex justify-content-between">
                  Download speed: <span>{marker.downloadSpeed}</span>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
      {mapDataY?.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.Latitude, lng: marker.Longitude }}
          icon={{
            url: iconUrls.yellow,
            scaledSize: new window.google.maps.Size(32, 32),
            anchor: new window.google.maps.Point(16, 32),
          }}
          onClick={() => {
            setSelectedMarkerY(marker);
            setSelectedMarkerPositionY({
              lat: marker.Latitude,
              lng: marker.Longitude,
            });
          }}
        >
          {selectedMarkerY === marker && (
            <InfoWindow
              position={{ lat: marker.Latitude, lng: marker.Longitude }}
              onCloseClick={() => {
                setSelectedMarkerY(null);
              }}
            >
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    marginBottom: "5px",
                  }}
                >
                  {marker.Email}
                </div>
                <div className="d-flex gap-3 mb-1">
                  <span>Lat: {marker.Latitude.toFixed(3)}</span>
                  <span>Long: {marker.Longitude.toFixed(3)}</span>
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    // </LoadScript >
    <></>
  );
}

export default MapComponent;
