export const MENUITEMS = [
  {
    Items: [
      {
        path: `/userlist`,
        icon: "ti-user",
        type: "link",
        active: false,
        selected: false,
        title: "Users",
      },
      {
        path: `/speedanalysis`,
        icon: "ti-server",
        type: "link",
        active: false,
        selected: false,
        title: "Speed Analysis",
      },
    ],
  },
];
