import React from "react";
import "../src/assets/css/style.css";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoggedIn, setToken } from "./app/store/slice/auth";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (token) {
      dispatch(setToken(token));
    }

    if (isLoggedIn) {
      dispatch(setLoggedIn(JSON.parse(isLoggedIn)));
    }
  }, [dispatch]);

  const { isLoggedIn } = useSelector((state) => state.auth);

  const isLoggedin =
    isLoggedIn || !!JSON.parse(localStorage.getItem("accessToken"));

  return (
    <div className="App">
      <BrowserRouter basename={process.env.REACT_APP_HOME_PAGE_URL}>
        <React.Suspense>
          <Routes isLoggedIn={isLoggedin} />
        </React.Suspense>
      </BrowserRouter>
    </div>
  );
}
export default App;
