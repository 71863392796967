import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/bundle";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

const LicenseModal = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  console.log("props", props.contentUrl);
  return (
    <Modal
      {...props}
      onHide={props.onHide}
      // className='screenshort-modals'
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Racing License</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4 text-center">
        <Row>
          <Col sm={12}>
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {props?.screenshot?.map((img, index) => (
                <SwiperSlide>
                  <img
                    className="slider-main-image"
                    src={img}
                    alt="image"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      // height: '330px',
                      objectFit: "cover",
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              style={{ cursor: "pointer" }}
              onSwiper={setThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper pt-4 secound-part"
            >
              {" "}
              {props?.screenshot?.map((img, index) => (
                <SwiperSlide className="h-50">
                  <img className="slider-image" src={img} alt="image" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
    // <>
    //     <Modal
    //         {...props}
    //         onHide={props.onHide}
    //         size='large'
    //         // size={props.contentUrl.endsWith('.pdf') ? "xl" : "large"}
    //         aria-labelledby="contained-modal-title-vcenter"
    //         centered
    //         backdrop="static"
    //     >
    //         <Modal.Header closeButton>
    //             <Modal.Title>Racing License
    //             </Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body className="text-center">
    //             <img src={props.screenshot} alt="Modal Content" width="100%" />
    //         </Modal.Body>
    //     </Modal>
    // </>
  );
};

export default LicenseModal;
